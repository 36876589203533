import React from "react";
import Layout from "../../components/layout";
import AppDevelopment from "../../components/views/solutions/app-development";

const AppDevelopmentPage = () => {
  return (
    <Layout>
      <AppDevelopment />
    </Layout>
  );
};

export default AppDevelopmentPage;
